var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("page-header", {
        attrs: {
          title: "어디로 가면 될까요?\n지역을 알려주세요.",
          subtitle: "걱정하지 마세요. 상세한 위치는 공개되지 않습니다."
        }
      }),
      _c("div", {
        staticClass: "ui-border-line ui-mt-1",
        staticStyle: { "background-color": "#e4e4e4" }
      }),
      _c("div", { staticClass: "ui-inputbox" }, [
        _c(
          "form",
          {
            ref: "form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return (function() {
                  return _vm.searchPlaces()
                })($event)
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.daum.keyword,
                  expression: "daum.keyword"
                }
              ],
              ref: "keyword",
              attrs: {
                type: "text",
                placeholder: "장소명 또는 주소 검색",
                size: "15",
                required: ""
              },
              domProps: { value: _vm.daum.keyword },
              on: {
                keyup: function(e) {
                  return _vm.searchPlaces(e.target.value)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.daum, "keyword", $event.target.value)
                }
              }
            })
          ]
        )
      ]),
      _c("div", {
        staticClass: "ui-border-line ui-mb-3",
        staticStyle: { "background-color": "#e4e4e4" }
      }),
      _vm.daum.onSearch
        ? _c("div", { staticClass: "search-list" }, [
            _c("div", { staticClass: "search-title" }, [_vm._v("검색 결과")]),
            _c(
              "ul",
              _vm._l(_vm.searchList, function(row, key) {
                return _c(
                  "li",
                  {
                    key: key,
                    on: {
                      click: function() {
                        return _vm.selectPlace(row)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(row.place_name))
                    ]),
                    _c("div", { staticClass: "addr" }, [
                      _vm._v(_vm._s(row.road_address_name || row.address_name))
                    ])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _c("daum-map", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.daum.onMap,
            expression: "daum.onMap"
          }
        ],
        ref: "Map",
        staticStyle: { margin: "0 -3rem" },
        attrs: {
          x: Number(_vm.postOffer.geoX),
          y: Number(_vm.postOffer.geoY),
          level: 3,
          "auto-mount": false
        },
        on: {
          click: function($event) {
            return _vm.$router.push("map/detail")
          }
        }
      }),
      _vm.validate
        ? _c("footer-box", {
            attrs: {
              submitText: "다음",
              submitCb: function() {
                return _vm.nextStep()
              },
              submitDisabled: !_vm.validate
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
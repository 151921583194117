<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`어디로 가면 될까요?\n지역을 알려주세요.`"
      subtitle="걱정하지 마세요. 상세한 위치는 공개되지 않습니다."
    >
    </page-header>

    <div class="ui-border-line ui-mt-1" style="background-color: #e4e4e4"></div>

    <div class="ui-inputbox">
      <form ref="form" @submit.prevent="() => searchPlaces()">
        <input
          ref="keyword"
          type="text"
          v-model="daum.keyword"
          @keyup="(e) => searchPlaces(e.target.value)"
          placeholder="장소명 또는 주소 검색"
          size="15"
          required
        >
      </form>
    </div>

    <div class="ui-border-line ui-mb-3" style="background-color: #e4e4e4"></div>

    <div class="search-list" v-if="daum.onSearch">
      <div class="search-title">검색 결과</div>
      <ul>
        <li v-for="(row, key) in searchList" :key="key" @click="() => selectPlace(row)">
          <div class="name">{{row.place_name}}</div>
          <div class="addr">{{row.road_address_name || row.address_name}}</div>
        </li>
      </ul>
    </div>

    <daum-map
      ref="Map"
      v-show="daum.onMap"
      style="margin: 0 -3rem;"
      :x="Number(postOffer.geoX)"
      :y="Number(postOffer.geoY)"
      :level="3"
      :auto-mount="false"
      @click="$router.push('map/detail')"
    >
    </daum-map>

    <footer-box
      v-if="validate"
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import DaumMap from '@/components/common/DaumMap'

export default {
  components: {
    PageHeader,
    FooterBox,
    DaumMap,
  },
  data() {
    return {
      keywordTimer: null,
      daum: {
        ps: null,
        keyword: null,
        onSearch: false,
        onMap: false,
      },
      searchList: []
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return (
          !isNaN(parseFloat(this?.postOffer?.geoX)) &&
          !isNaN(parseFloat(this?.postOffer?.geoY)) &&
          this?.daum?.keyword &&
          this.keywordTimer === null
        )
      },
    },
  },
  mounted() {
    // 장소 검색 객체를 생성합니다
    this.daum.ps = new daum.maps.services.Places()

    if (this.postOffer.geoX && this.postOffer.geoY && this.postOffer.location && this.postOffer.address) {
      this.daum.keyword = this.postOffer.address
      this.daum.onSearch = false
      this.daum.onMap = true
      this.$nextTick().then(() => {
        this.$refs.Map.setMount()
      })
    }
  },
  methods: {
    searchPlaces(keyword = this.daum.keyword) {
      if (!keyword.replace(/^\s+|\s+$/g, '')) {
        return false
      }

      clearTimeout(this.keywordTimer)
      this.keywordTimer = setTimeout(() => {
        this.keywordTimer = null
        // 장소검색 객체를 통해 키워드로 장소검색을 요청합니다
        this.daum.ps.keywordSearch(keyword, this.placesSearchCB)
      }, 1000)
    },
    placesSearchCB(data, status) {
      try {
        if (status === daum.maps.services.Status.OK) {

          this.daum.onMap = false
          this.postOffer = {
            geoX: null,
            geoY: null,
            location: null,
            address: null,
          }
          this.daum.onSearch = true
          this.searchList = data


        } else if (status === daum.maps.services.Status.ZERO_RESULT) {
          this.daum.onMap = false
          this.postOffer = {
            geoX: null,
            geoY: null,
            location: null,
            address: null,
          }
          this.daum.onSearch = true
          this.searchList = []
          // this.$alert('검색 결과가 존재하지 않습니다.')
          return
        } else if (status === daum.maps.services.Status.ERROR) {
          this.daum.onMap = false
          this.postOffer = {
            geoX: null,
            geoY: null,
            location: null,
            address: null,
          }
          this.daum.onSearch = true
          this.searchList = []
          // this.$alert('검색 결과 중 오류가 발생했습니다.')
          return
        }

        this.$refs.keyword.blur()
      } catch (e) {
        log.error(e)
      }
    },
    selectPlace(row) {
      let address = row.address_name || row.road_address_name
      let location = '0'
      let locationKey = address.replace(/^(..).+/, '$1')
      let geoX = parseFloat(row.y)
      let geoY = parseFloat(row.x)

      for (let key in this.$config.constant.location) {
        if (this.$config.constant.location[key] === locationKey) {
          location = key
          break
        }
      }

      this.daum.onSearch = false
      this.daum.keyword = address
      this.postOffer = {
        geoX,
        geoY,
        location,
        address,
      }
      this.daum.onMap = true
      this.$refs.Map.setMount()
    },
    nextStep() {
      this.$parent.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>
.search-list {
  .search-title {
    color: #b6b6b6;
    font-size: 1.6rem;
  }
  ul {
    li {
      margin: 2rem 0;
      .name {
        font-size: 2rem;
        color: #000;
      }
      .addr{
        font-size: 1.4rem;
        color: #b6b6b6;
      }
    }
  }
}
</style>
